import { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
// import logo from "./CRXLOGO.png";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PersonIcon from "@mui/icons-material/Person";
import { AuthContext } from "../../context/authcontext";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Loading from "../commonComponet/loading/loading";
// import Dashboard from "../Dashboard/Dashboard";
import { deepOrange } from "@mui/material/colors";

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CallIcon from '@mui/icons-material/Call';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';

import AppConstant from "../../constants/AppConstant"

function NavScrollExample({ Outlet }) {
  const { user, auth, setAuth, loading, setLoading } =
    useContext(AuthContext);
  axios.defaults.withCredentials = true;

  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(false);
  const [selectedList, setSelectedList] = useState(0);

  const toggleState = (state, setState) => {
    setState(!state);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      const logoutApiResponse = await axios.get(
        `${AppConstant.baseURL}/auth/logout`
      );
      if (logoutApiResponse.data.success === true) {
        setAuth(0);
        toast.success("Logout Successfully !");
      }
    } catch (error) {
      console.log(error);
      toast.success("SomeThing wents wrong!");
    }
  };


  const marginn = {
    marginLeft: "25px",
  };
  useEffect(() => {
    // console.log(location.pathname.split("/")[2], "location");
    setSelectedList(location.pathname.split("/")[2]);
  });

  // console.log(loading, "loading");
  // console.log(auth, "auth");

  return (
    <>
      <AppBar expand="lg" color="" position="fixed">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <div className="bg-[#342b57] p-2 rounded-full flex justify-center items-center">
            <img
              src="/assets/zonet1.png"
              alt="CRXLOGO"
              className="w-[100px] "
            />
          </div>

          <div>
            <IconButton
              aria-controls="avatar-menu"
              aria-haspopup="true"
              onClick={handleClick}
              color="inherit"
            >
              <Avatar sx={{ bgcolor: deepOrange[500] }}>
                {user?.username ? (
                  user?.username[0].toUpperCase()
                ) : (
                  <AccountCircleIcon />
                )}
              </Avatar>
            </IconButton>
            <Menu
              id="avatar-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                sx={{ width: "200px", justifyContent: "center" }}
                onClick={() => setSelectedList("profile")}
              >
                <PersonOutlineIcon />
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to={"/dashboard/profile"}
                >
                  &nbsp;&nbsp;My Profile
                </Link>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  // deleteCookie("userToken");
                  handleLogout();
                  navigate("/login");
                }}
                // onClick={handleClose}
                sx={{ width: "200px", justifyContent: "center" }}
              >
                <LogoutIcon />
                &nbsp;&nbsp;Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <List
        sx={{
          width: "250px",
          height: "90vh",
          marginTop: "70px",
          boxShadow: "15px 10px 22px 3px rgba(0,0,0,0.1)",
          position: "fixed",
          zIndex: 8,
          overflow: "auto",
        }}
      >
       
        {[
          {name:"profile",html: <PersonIcon sx={{ color: "gray", paddingRight: "10px" ,fontSize:"30px"}} /> },
          {name:"faq", html: <QuestionMarkIcon sx={{ color: "gray",paddingRight: "10px" ,fontSize:"30px" }} />},
          {name:"contactus", html:<CallIcon sx={{ color: "gray",paddingRight: "10px" ,fontSize:"30px"}} />},
          {name:"testimonials", html:<FeedbackIcon sx={{ color: "gray", paddingRight: "10px" ,fontSize:"30px"}} />},
          {name:"blog", html:<ViewHeadlineIcon sx={{ color: "gray",paddingRight: "10px" ,fontSize:"30px" }} />},
        ].map((obj, index) => {
          return (
            <ListItemButton
              sx={{
                bgcolor:
                  selectedList === index || selectedList === obj.name
                    ? "#e4e4e4"
                    : "",
              }}
              key={obj.text}
              divider
              onClick={() => {
                setSelectedList(index);
                navigate(`/dashboard/${obj.name}`);
              }}
            >
              {/* <PersonIcon sx={{ color: "gray", padding: "5px" }} /> */}
              
              {obj.html}
              <ListItemText
                className="capitalize"
                primary={obj.name}
                primaryTypographyProps={{
                  fontWeight: "bold",
                }}
              />
            </ListItemButton>
            
          );
        })}
       
      </List>
      {auth ? (
        <div className="ml-[270px] pt-[70px] h-[100vh] relative">
          {loading && (
            <div className="rounded duration-100 absolute bg-[#212121]/70 left-0 right-0 h-[90%] z-[100] flex justify-center items-center">
              <Loading />
            </div>
          )}
          {Outlet}
        </div>
      ) : (
        <div className="pl-[270px] pt-[70px] h-[100vh]">
          Not Authorized , Pls Login
          <Link to={"/login"}>
            <button className="block m-2 px-2 py-1 bg-blue-500 rounded text-white">
              Login
            </button>
          </Link>
        </div>
      )}
    </>
  );
}

export default NavScrollExample;
