import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/authcontext";
import BasicSelect from "../../commonComponet/singleSelete";
import { TextField, Button, Stack, } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import AppConstant from "../../../constants/AppConstant"

const FaqUpdateModule = ({ setOpen, rowData = "" }) => {
  const { user, auth, setAuth, fetchAgain, setFetchAgain, loading, setLoading } =
    useContext(AuthContext);

  const [active, setActive] = useState(rowData?.active);
  const [pageurl, setPageUrl] = useState(rowData?.page_url)
  const [faqData, setFaqData] = useState({
    id: rowData?.id,
    questions: rowData?.questions,
    answer: rowData?.answer,
    page_url: pageurl,
    active: active,
  });
  console.log(rowData, "rowData");
  console.log(faqData, "faqData");

  const clearAll = () => {
    // questionRef.current.value = "";
    // answerRef.current.value = "";
    // pageurlRef.current.value = "";
    // activeRef.current.value = null;
    setFaqData({});
  };

  const updateFaq = async () => {
    // const questions = questionRef.current.value;
    // const answer = answerRef.current.value;
    // const page_url = pageurlRef.current.value;
    // const active = activeRef.current.value;
    // const id = rowData.id;
    // const data = { id, questions, answer, page_url, active };
    // console.log(data, "data");
    try {
      const update = await axios.patch(
        `${AppConstant.baseURL}/faq/update`,
        faqData
      );
      if (update.data.code === 201) {
        setOpen(false);
        toast.success("Faq Updated successfully !");
        setFetchAgain((prev) => !prev);
        clearAll();
      } else {
        setOpen(false);
        toast.error("Something went wrong !");
      }
    } catch (error) {
      console.log(error);
    }

    // console.log(create, "createfaq");
  };

  const handleUpdate = () => {
    updateFaq();
    // console.log("submitting form");
    // console.log(questionRef.current.value, "question Ref");
    // console.log(pageurlRef.current.value, "pageurl Ref");
  };

  useEffect(() => {
    if (rowData) {
      setFaqData({
        id: rowData?.id,
        questions: rowData?.questions,
        answer: rowData?.answer,
        page_url: rowData?.page_url,
        active: active,
      });
    }
  }, [rowData]);

  useEffect(() => {
    setFaqData({ ...faqData, page_url: pageurl });
  }, [pageurl]);


  useEffect(() => {
    setFaqData({ ...faqData, active: active });
  }, [active]);
  // console.log(rowData, "rowData");
  return (
    <div className="flex justify-center m-auto items-center  w-[70%]">
      <div className="bg-[#f4f4f4] mt-5 p-4 rounded-lg flex flex-col gap-4 text-nowrap w-full">
        <h1 className="text-center font-semibold text-xl tracking-wide">
           Update FAQ
        </h1>

        <TextField
          id="filled-multiline-flexible"
          label="Questions"
          multiline
          onChange={(e) =>
            setFaqData({ ...faqData, [e.target.name]: e.target.value })
          }
          maxRows={4}
          variant="outlined"
          name="questions"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${faqData?.questions}`}
        />

        <TextField
          id="filled-multiline-flexible"
          label="Answer"
          multiline
          onChange={(e) =>
            setFaqData({ ...faqData, [e.target.name]: e.target.value })
          }
          maxRows={4}
          variant="outlined"
          name="answer"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${faqData?.answer}`}
        />


        <div className="w-fit">

          <BasicSelect
          title="Page Url:"
          value={pageurl}
          setvalue={setPageUrl}
          
          list={[
            { id: "aboutus", name: "aboutus" },
            { id: "contactus", name: "contactus" },
          ]}
        />
        </div>


        <div className="flex gap-4  px-2 py-1 rounded ">
          {/* <label>Active:</label> */}
          {/* <input
            ref={activeRef}
            defaultValue={rowData?.active}
            name="active"
            type="number"
            className="w-full outline-none bg-transparent tracking-wide"
            placeholder="Enter Active"
          /> */}
          <BasicSelect
            title="Active"
            value={active}
            setvalue={setActive}
            list={[
              { id: 0, name: "false" },
              { id: 1, name: "true" },
            ]}
          />
        </div>
        {/* <div className="flex gap-4 m-auto">
          <div
            className="text-white bg-red-700 px-2 py-1 rounded cursor-pointer"
            onClick={() => setOpen(false)}
          >
            Discard
          </div>
          <div
            className="text-white bg-green-600 px-2 py-1 rounded cursor-pointer"
            onClick={handleUpdate}
          >
            Update
          </div>
        </div> */}
        <Stack direction="row" spacing={2} justifyContent={"center"}>
          <Button
            color="error"
            variant="contained"
            onClick={() => setOpen(false)}
          >
            Discard
          </Button>
          <LoadingButton
            color="success"
            size="medium"
            loading={loading}
            variant="contained"
            onClick={handleUpdate}
          >
            <span>Update</span>
          </LoadingButton>
        </Stack>
      </div>
    </div>
  );
};
export default FaqUpdateModule;
