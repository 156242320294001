import { useContext, useEffect, useState } from "react";
import CustomTableComponent from "../customTableComponent/CustomTableComponent";
import TestimonialAddModule from "./addModule";
import axios from "axios";
import { AuthContext } from "../../../context/authcontext";
import { toast } from "react-toastify";
import TestimonialUpdateModule from "./updateModule";
import AppConstant from "../../../constants/AppConstant";

const columns = [
  { id: 1, name: "user_name", label: "Username" },
  {
    id: 1,
    name: "image",
    label: "Image",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "image") {
          return (
            <td className={`py-2 pr-10 max-w-[50px] relative `}>
              <div className="size-[40px] ">
                <img
                  className=" hover:scale-[2] duration-100 hover:z-[10]"
                  src={`${AppConstant.imageURL}/${row[column.name]}`}
                  alt="image"
                />
              </div>
            </td>
          );
        }
      },
    },
  },
  { id: 1, name: "designation", label: "Designation" },
  { id: 1, name: "comment", label: "Comment" },
  { id: 1, name: "active", label: "Active   " },
];
const data = [
  // {
  //   id: 1,
  //   user_name: "kamlesh",
  //   image: "kuch to h",
  //   designation: "Frontend developer",
  //   comment: "nothing",
  //   active: 1,
  // },
];

const Testimonials = () => {
  axios.defaults.withCredentials = true;
  const [testimonial, setTestimonial] = useState([]);
  const {
    user,
    auth,
    setAuth,
    fetchAgain,
    setFetchAgain,
    loading,
    setLoading,
  } = useContext(AuthContext);

  const deleteapi = async (id) => {
    // console.log(id, "deleteid");
    const deleteApiCall = await axios.post(
      `${AppConstant.baseURL}/testimonials/deletetestimonialsdetails`,
      { id: id }
    );
    if (deleteApiCall.data.code === 200) {
      toast.success("deleted successfully");
      setFetchAgain((prev) => !prev);
    } else {
      toast.error("something went wrong");
    }
    // console.log(deleteApiCall, "delete api call");
  };
  const statusChange = async (id, active) => {
    // console.log(id, active, "deleteid");
    try {
      const StatusApiCall = await axios.post(
        `${AppConstant.baseURL}/testimonials/status`,
        { id: id, active: active }
      );
      if (StatusApiCall.data.code === 200) {
        toast.success(StatusApiCall.data.message);
        setFetchAgain((prev) => !prev);
      } else {
        toast.error("something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const getTestimonial = async () => {
      try {
        setLoading(true);
        const getallTestimonial = await axios.get(
          `${AppConstant.baseURL}/testimonials/getalltestimonials`,
          {
            signal: controller.signal,
          }
        );
        if (getallTestimonial.data.code === 200) {
          setTestimonial(getallTestimonial.data.data);
        }
        setLoading(false);
        // console.log(getallTestimonial.data.data, "get testimonial");
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getTestimonial();
    return () => {
      controller.abort();
    };
  }, [fetchAgain]);

  // useEffect(() => {
  //   console.log(testimonial, "testimonial state updated"); // Debug the state updates
  // }, [testimonial]);
  // console.log(testimonial, "testimonial setted");
  return (
    <div className="border">
      <CustomTableComponent
        title=""
        data={testimonial}
        columns={columns}
        search={true}
        dateFilter={false}
        download={true}
        // columnShow={5}
        Module={TestimonialAddModule}
        deleteapi={deleteapi}
        UpdateModule={TestimonialUpdateModule}
        statusChange={statusChange}
      />
    </div>
  );
};
export default Testimonials;

//  option: {
//       tablebodyCustom: (row, column) => {
//         if (column.name === "weight") {
//           return <td className={`py-2 pr-24`}>{`${row[column.name]} g`}</td>;
//         }
//       },
//     },
