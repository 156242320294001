import { useContext, useEffect, useState } from "react";
import CustomTableComponent from "../customTableComponent/CustomTableComponent";
import FaqAddModule from "./addModule";
import axios from "axios";
import { AuthContext } from "../../../context/authcontext";
import { toast } from "react-toastify";
import FaqUpdateModule from "./updateModule";
import AppConstant from "../../../constants/AppConstant"
const columns = [
  { id: 1, name: "questions", label: "Question" },
  { id: 2, name: "answer", label: "Answer" },
  { id: 3, name: "page_url", label: "Page Url" },
  { id: 4, name: "active", label: "Active" },
];
const data = [
  // {
  //   id: 1,
  //   question: "how are you?",
  //   answer: "i am fine",
  //   pageurl: "aboutus",
  //   active: 1,
  // },
  // d
];

const Faq = () => {
  axios.defaults.withCredentials = true;
  const [faq, setFaq] = useState([]);
  const {
    user,
    auth,
    setAuth,
    fetchAgain,
    setFetchAgain,
    loading,
    setLoading,
  } = useContext(AuthContext);

  const deleteapi = async (id) => {
    try {
      const deleteApiCall = await axios.post(
        `${AppConstant.baseURL}/faq/delete`,
        { id: id }
      );
      if (deleteApiCall.data.code === 200) {
        toast.success("deleted successfully");
        setFetchAgain((prev) => !prev);
      } else {
        toast.error("something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
    // console.log(id, "deleteid");

    // console.log(deleteApiCall, "delete api call");
  };

  const statusChange = async (id, active) => {
    // console.log(id, active, "deleteid");
    try {
      const StatusApiCall = await axios.post(
        `${AppConstant.baseURL}/faq/status`,
        { id: id, active: active }
      );
      if (StatusApiCall.data.code === 200) {
        toast.success(StatusApiCall.data.message);
        setFetchAgain((prev) => !prev);
      } else {
        toast.error("something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const getFaq = async () => {
      try {
        setLoading(true);
        const getallFaq = await axios.get(
          `${AppConstant.baseURL}/faq/getallfaq`,
          {
            signal: controller.signal,
          }
        );
        if (getallFaq.data.code === 200) {
          setFaq(getallFaq.data.data);
        }
        setLoading(false);
        // console.log(getallFaq.data.data, "get faq");
        setLoading(false);
      } catch (error) {
        console.log(error, "error in fatching faq");
        setLoading(false);
      }
    };
    getFaq();

    return () => {
      controller.abort();
    };
  }, [fetchAgain]);

  // useEffect(() => {
  //   console.log(faq, "faq state updated"); // Debug the state updates
  // }, [faq]);
  // console.log(faq, "faq setted");
  return (
    <div className="border rounded">
      <CustomTableComponent
        title=""
        data={faq}
        columns={columns}
        search={true}
        dateFilter={false}
        download={false}
        // columnShow={5}
        Module={FaqAddModule}
        deleteapi={deleteapi}
        UpdateModule={FaqUpdateModule}
        statusChange={statusChange}
      />
    </div>
  );
};
export default Faq;

//  option: {
//       tablebodyCustom: (row, column) => {
//         if (column.name === "weight") {
//           return <td className={`py-2 pr-24`}>{`${row[column.name]} g`}</td>;
//         }
//       },
//     },
