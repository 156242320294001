// "use strict";
Object.defineProperty(exports, "__esModule", {
  value: true,
});
console.log(process.env.NODE_ENV, "process.env.NEXT_PUBLIC_NODE_ENV");
if (process.env.NODE_ENV == "development") {
  exports.default = {
    websiteURL: "https://localhost:3001/",
    // baseURL: "https://zonettech.com/api",
    imageURL: "https://cdn1.zonettech.com",
    // imageURL: "https://cdn.zonettech.com/",
    baseURL: "http://localhost:4040/api",
    backendURL: "http://localhost:4040/api",
  };
} else {
  exports.default = {
    websiteURL: "https://team.zonettech.com/",
    baseURL: "https://team.zonettech.com/api",
    imageURL: "https://cdn1.zonettech.com",
    // baseURL: "http://localhost:5300/api",
    backendURL: "https://team.zonettech.com/api",
  };
}
// wd
