import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/authcontext";
import BasicSelect from "../../commonComponet/singleSelete";
import { Button, Stack, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import AppConstant from "../../../constants/AppConstant"

const FaqAddModule = ({ setOpen }) => {
  const {
    user,
    auth,
    setAuth,
    fetchAgain,
    setFetchAgain,
    loading,
    setLoading,
  } = useContext(AuthContext);

  const [active, setActive] = useState(0);
  const [pageurl, setPageUrl] = useState(null);
  const [faqData, setFaqData] = useState({
    questions: "",
    answer: "",
    page_url: pageurl,
    active: active,
  });

  const clearAll = () => {
    setFaqData({});
  };

  const createFaq = async () => {
    // console.log(faqData, "faqData");
    try {
      // setLoading(true);
      const create = await axios.post(
        `${AppConstant.baseURL}/faq/create`,
        faqData
      );
      if (create.data.code === 201) {
        toast.success("Faq Added successfully !");
        setFetchAgain((prev) => !prev);
        setOpen(false);
        clearAll();
      } else {
        toast.error("Something went wrong !");
        setOpen(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong !");
      setLoading(false);
    }

    // console.log(create, "createfaq");
  };

  const handleSubmit = () => {
    setLoading(true);
    createFaq();
  };

  useEffect(() => {
    setFaqData({ ...faqData, active: active });
  }, [active]);

  useEffect(() => {
    setFaqData({ ...faqData, page_url: pageurl });
  }, [pageurl]);

  return (
    <div className="flex m-auto w-[70%] ">
      <div className="bg-[#f4f4f4]  mt-5 p-4 rounded-lg flex flex-col gap-4 text-nowrap  w-full">
        <h1 className="text-center font-semibold text-xl tracking-wide">
          Add FAQ
        </h1>
        <TextField
          id="filled-multiline-flexible"
          label="Questions"
          multiline
          onChange={(e) =>
            setFaqData({ ...faqData, [e.target.name]: e.target.value })
          }
          maxRows={4}
          variant="outlined"
          name="questions"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${faqData?.questions}`}
        />
        {/* <div className="flex gap-4 border border-[#999] px-2 py-1 rounded w-full"> */}
        {/* <label>Question:</label>
          <input
            onChange={(e) =>
              setFaqData({ ...faqData, [e.target.name]: e.target.value })
            }
            // ref={questionRef}
            type="text"
            name="questions"
            value={faqData?.questions}
            className="w-full outline-none bg-transparent tracking-wide "
            placeholder="Enter question"
          /> */}

        {/* </div> */}

        <TextField
          id="filled-multiline-flexible"
          label="Answer"
          multiline
          onChange={(e) =>
            setFaqData({ ...faqData, [e.target.name]: e.target.value })
          }
          maxRows={4}
          variant="outlined"
          name="answer"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${faqData?.answer}`}
        />
        {/* <div className="flex gap-4 border  border-[#999] px-2 py-1 rounded ">
          <label>Answer:</label>
          <input
            onChange={(e) =>
              setFaqData({ ...faqData, [e.target.name]: e.target.value })
            }
            // ref={answerRef}
            type="text"
            value={faqData?.answer}
            name="answer"
            className="w-full outline-none bg-transparent tracking-wide"
            placeholder="Enter Answer"
          />
        </div> */}
        <div className="w-fit">
          <BasicSelect
            title="Page Url:"
            value={pageurl}
            setvalue={setPageUrl}
            list={[
              { id: "aboutus", name: "aboutus" },
              { id: "contactus", name: "contactus" },
            ]}
          />
        </div>
        {/* <div className="flex gap-4 border  border-[#999] px-2 py-1 rounded "> */}
        {/* <label>Page Url:</label>
          <select
            onChange={(e) =>
              setFaqData({ ...faqData, [e.target.name]: e.target.value })
            }
            // ref={pageurlRef}
            value={faqData?.page_url}
            name="page_url"
            className="w-full outline-none bg-transparent tracking-wide"
          >
            <option value="aboutus">aboutus</option>
            <option value="contactus">contactus</option>
          </select> */}
        {/* <input
            name="pageurl"
            type="text"
            className="w-full outline-none bg-transparent tracking-wide"
            placeholder="Enter Page url"
          /> */}
        {/* </div> */}
        {/* <div className="flex gap-4 border  border-[#999] px-2 py-1 rounded "> */}
        {/* <label>Active:</label>
          <input
            value={faqData?.active}
            onChange={(e) => setFaqData({ ...faqData, active: e.target.value })}
            // ref={activeRef}
            name="active"
            type="number"
            className="w-full outline-none bg-transparent tracking-wide"
            placeholder="Enter Active"
          /> */}
        <div className="w-fit">
          <BasicSelect
            title="Active"
            value={active}
            setvalue={setActive}
            list={[
              { id: 0, name: "false" },
              { id: 1, name: "true" },
            ]}
          />
        </div>
        {/* </div> */}

        <Stack direction="row" spacing={2} justifyContent={"center"}>
          <Button
            color="error"
            variant="contained"
            onClick={() => setOpen(false)}
          >
            Discard
          </Button>
          <LoadingButton
            color="success"
            size="medium"
            loading={loading}
            variant="contained"
            onClick={handleSubmit}
          >
            <span>Submit</span>
          </LoadingButton>
        </Stack>

        {/* <div className="flex gap-4 m-auto">
          <div
            className="text-white bg-red-700 px-2 py-1 rounded cursor-pointer"
            onClick={() => setOpen(false)}
          >
            Discard
          </div>
          <div
            className="text-white bg-green-600 px-2 py-1 rounded cursor-pointer"
            onClick={handleSubmit}
          >
            Submit
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default FaqAddModule;
