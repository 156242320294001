import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/authcontext";
import BasicSelect from "../../commonComponet/singleSelete";
import { Button, Stack, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import InputFileUpload from "../../commonComponet/fileUpload/fileUpload";
import AppConstant from "../../../constants/AppConstant"

const TestimonialAddModule = ({ setOpen }) => {
  const {
    user,
    auth,
    setAuth,
    fetchAgain,
    setFetchAgain,
    loading,
    setLoading,
  } = useContext(AuthContext);

  // const user_nameRef = useRef(null);
  // const imageRef = useRef(null);
  // const designationRef = useRef(null);
  // const commentRef = useRef(null);
  // const activeRef = useRef(null);

  const [active, setActive] = useState(0);
  // const [pageurl, setPageUrl] = useState(null);
  const [pic, setPic] = useState(null);

  const [testimonialData, setTestimonialData] = useState({
    user_name: "",
    image: "",
    designation: "",
    comment: "",
    active: active,
  });

  const clearAll = () => {
    setTestimonialData({
      user_name: "",
      image: "",
      designation: "",
      comment: "",
      active: 0,
    });
  };

  const createTestimonial = async () => {
    const formData = new FormData();
    formData.append("user_name", testimonialData?.user_name);
    formData.append("image", testimonialData?.image);
    formData.append("designation", testimonialData?.designation);
    formData.append("comment", testimonialData?.comment);
    formData.append("active", testimonialData?.active);
    // const user_name = user_nameRef.current.value;
    // const image = imageRef.current.value;
    // const designation = designationRef.current.value;
    // const comment = commentRef.current.value;
    // const active = activeRef.current.value;
    // const data = { user_name, image, designation, comment, active };
    // formData.forEach((value, key) => {
    //   console.log(`${key}:`, value, "formData testimonial");
    // });
    // console.log(formData, "formData testimonial");
    try {
      setLoading(true);
      const create = await axios.post(
        `${AppConstant.baseURL}/testimonials/createtestimonials`,
        formData
      );
      if (create.data.code === 201) {
        setOpen(false);
        toast.success("Testimonial Added successfully !");
        setFetchAgain((prev) => !prev);
        clearAll();
      } else {
        setOpen(false);
        toast.error("Something went wrong !");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong !");
      setLoading(false);
    }

    //   console.log(create, "createtestimonial");
  };

  const handleSubmit = () => {
    createTestimonial();
    clearAll();

    // console.log("submitting form");
    //   console.log(user_nameRef.current.value, "question Ref");
    //   console.log(imageRef.current.value, "pageurl Ref");
  };

  const picUpload = (e) => {
    const [file] = e.target.files;
    setPic(URL.createObjectURL(file));
    setTestimonialData({
      ...testimonialData,
      image: file,
    });
    // setFile(file);
  };

  useEffect(() => {
    setTestimonialData({ ...testimonialData, active: active });
  }, [active]);

  return (
    <div className="flex w-[70%] m-auto">
      <div className="bg-[#f4f4f4] w-full mt-5 p-4 rounded-lg flex flex-col gap-4 text-nowrap">
        <h1 className="text-center font-semibold text-xl tracking-wide">
          Add Testimonial
        </h1>
        <TextField
          id="filled-multiline-flexible"
          label="Username:"
          multiline
          onChange={(e) =>
            setTestimonialData({
              ...testimonialData,
              [e.target.name]: e.target.value,
            })
          }
          maxRows={4}
          variant="outlined"
          name="user_name"
          type="text"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${testimonialData?.user_name}`}
        />

        <div className="flex gap-4 items-center">
          <InputFileUpload title={"Testimonial Image"} onChange={picUpload} />
          {pic ? (
            <img src={pic} className="size-12 hover:scale-125 duration-100" />
          ) : (
            <p>Upload Image</p>
          )}
        </div>

        {/* <div className="flex gap-4 border  border-[#999] px-2 py-1 rounded ">
          <label>Image:</label>
          <input
            type="file"
            name="image"
            onChange={picUpload}
            className="w-full outline-none bg-transparent tracking-wide"
          />
          {pic ? <img src={pic} className="size-12" /> : <p>Upload Image</p>}
        </div> */}

        <TextField
          id="filled-multiline-flexible"
          label="Designation:"
          multiline
          onChange={(e) =>
            setTestimonialData({
              ...testimonialData,
              [e.target.name]: e.target.value,
            })
          }
          maxRows={4}
          variant="outlined"
          name="designation"
          type="text"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${testimonialData?.designation}`}
        />

        <TextField
          id="filled-multiline-flexible"
          label="Comment:"
          multiline
          onChange={(e) =>
            setTestimonialData({
              ...testimonialData,
              [e.target.name]: e.target.value,
            })
          }
          maxRows={4}
          variant="outlined"
          name="comment"
          type="text"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${testimonialData?.comment}`}
        />

        {/* <div className="flex gap-4 border  border-[#999] px-2 py-1 rounded ">
          <label>Active:</label> */}
        {/* <input
            ref={activeRef}
            name="active"
            type="number"
            className="w-full outline-none bg-transparent tracking-wide"
            placeholder="Enter Active"
          /> */}
        {/* </div> */}
        <BasicSelect
          title="Active"
          value={active}
          setvalue={setActive}
          list={[
            { id: 0, name: "false" },
            { id: 1, name: "true" },
          ]}
        />
        <Stack direction="row" spacing={2} justifyContent={"center"}>
          <Button
            color="error"
            variant="contained"
            onClick={() => setOpen(false)}
          >
            Discard
          </Button>
          <LoadingButton
            color="success"
            size="medium"
            loading={loading}
            variant="contained"
            onClick={handleSubmit}
          >
            <span>Submit</span>
          </LoadingButton>
        </Stack>
      </div>
    </div>
  );
};
export default TestimonialAddModule;
