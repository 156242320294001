import { useContext, useEffect, useState } from "react";
import CustomTableComponent from "../customTableComponent/CustomTableComponent";
// import FaqAddModule from "./addModule";
import axios from "axios";
import { AuthContext } from "../../../context/authcontext";
import { toast } from "react-toastify";
import BlogPostAddModule from "./addModule";
import BlogPostUpdateModule from "./updateModule";
// import FaqUpdateModule from "./updateModule";
import AppConstant from "../../../constants/AppConstant";
// dffs
const columns = [
  { id: 1, name: "title", label: "Title" },
  {
    id: 2,
    name: "featured_image",
    label: "Featured Image",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "featured_image") {
          return (
            <td className={`py-2 pr-10  relative `}>
              <div className="w-[40px]">
                <img
                  className=" hover:scale-[2] duration-100 hover:z-[10] object-cover"
                  src={`${AppConstant.imageURL}/${row[column.name]}`}
                  alt="logo"
                />
              </div>
            </td>
          );
        }
      },
    },
  },
  { id: 3, name: "short_description", label: "Short Description" },
  { id: 4, name: "description", label: "Description   " },
  { id: 5, name: "quotes", label: "Quotes" },
  { id: 6, name: "author_name", label: "Author Name" },
  { id: 7, name: "social_links", label: "Social Links" },
  { id: 8, name: "related_projects", label: "Related Projects" },
  { id: 9, name: "related_language", label: "Related Language" },
  { id: 10, name: "cat_arr", label: "Cat Arr" },
  { id: 11, name: "priority", label: "Priority" },
  { id: 12, name: "active", label: "Active" },
];
const data = [
  // {
  //   id: 1,
  //   question: "how are you?",
  //   answer: "i am fine",
  //   pageurl: "aboutus",
  //   active: 1,
  // },
];

const Blog = () => {
  axios.defaults.withCredentials = true;
  const [blogs, setBlogs] = useState([]);
  const {
    user,
    auth,
    setAuth,
    fetchAgain,
    setFetchAgain,
    loading,
    setLoading,
  } = useContext(AuthContext);

  const deleteapi = async (id) => {
    try {
      // console.log(id, "deleteid");
      const deleteApiCall = await axios.post(
        `${AppConstant.baseURL}/blog/deleteBlogDetails`,
        { id: id }
      );
      if (deleteApiCall.data.code === 200) {
        toast.success("deleted successfully");
        setFetchAgain((prev) => !prev);
      }
      // console.log(deleteApiCall, "delete api call");
    } catch (error) {
      console.log(error);
    }
  };
  const statusChange = async (id, active) => {
    // console.log(id, active, "deleteid");
    try {
      const StatusApiCall = await axios.post(
        `${AppConstant.baseURL}/blog/status`,
        { id: id, active: active }
      );
      if (StatusApiCall.data.code === 200) {
        toast.success(StatusApiCall.data.message);
        setFetchAgain((prev) => !prev);
      } else {
        toast.error("something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const getBlogs = async () => {
      try {
        setLoading(true);
        const getallBlogs = await axios.get(
          `${AppConstant.baseURL}/blog/getAllBlogDetails`,
          {
            signal: controller.signal,
          }
        );
        if (getallBlogs.data.code === 200) {
          setBlogs(getallBlogs.data.data);
        }
        // console.log(getallBlogs.data.data, "get faq");
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getBlogs();
    return () => {
      controller.abort();
    };
  }, [fetchAgain]);

  return (
    <div className="border">
      <CustomTableComponent
        title=""
        data={blogs}
        columns={columns}
        search={true}
        dateFilter={false}
        download={true}
        // columnShow={5}
        Module={BlogPostAddModule}
        deleteapi={deleteapi}
        UpdateModule={BlogPostUpdateModule}
        statusChange={statusChange}
      />
    </div>
  );
};
export default Blog;

//  option: {
//       tablebodyCustom: (row, column) => {
//         if (column.name === "weight") {
//           return <td className={`py-2 pr-24`}>{`${row[column.name]} g`}</td>;
//         }
//       },
//     },
