import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/authcontext";
import BasicSelect from "../../commonComponet/singleSelete";
import { TextField, Button, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import InputFileUpload from "../../commonComponet/fileUpload/fileUpload";
import AppConstant from "../../../constants/AppConstant";

const TestimonialUpdateModule = ({ setOpen, rowData }) => {
  const {
    user,
    auth,
    setAuth,
    fetchAgain,
    setFetchAgain,
    loading,
    setLoading,
  } = useContext(AuthContext);
  // const user_nameRef = useRef(null);
  // const imageRef = useRef(null);
  // const designationRef = useRef(null);
  // const commentRef = useRef(null);
  // const activeRef = useRef(null);

  const [active, setActive] = useState(rowData?.active);
  const [pic, setPic] = useState(null);
  const oldImagePath = rowData?.image;
  const [testimonialData, setTestimonialData] = useState({
    id: rowData?.id,
    user_name: rowData?.user_name,
    image: rowData?.image,
    designation: rowData?.designation,
    comment: rowData?.comment,
    active: active,
  });

  const clearAll = () => {
    // user_nameRef.current.value = "";
    // imageRef.current.value = "";
    // designationRef.current.value = "";
    // commentRef.current.value = "";
    // activeRef.current.value = null;
    setTestimonialData({
      user_name: null,
      image: null,
      designation: null,
      comment: null,
      active: false,
    });
  };
  const picUpload = (e) => {
    const [file] = e.target.files;
    setPic(URL.createObjectURL(file));
    setTestimonialData({
      ...testimonialData,
      image: file,
    });
    // setFile(file);
  };

  const updateTestimonial = async () => {
    const formData = new FormData();
    formData.append("id", rowData?.id);
    formData.append("user_name", testimonialData?.user_name);
    formData.append("image", testimonialData?.image);
    formData.append("designation", testimonialData?.designation);
    formData.append("comment", testimonialData?.comment);
    formData.append("active", testimonialData?.active);
    formData.append("oldImagePath", oldImagePath);

    // const user_name = user_nameRef.current.value;
    // const image = imageRef.current.value;
    // const designation = designationRef.current.value;
    // const comment = commentRef.current.value;
    // const active = activeRef.current.value;
    // const id = rowData.id;
    // const data = { id, user_name, image, designation, comment, active };
    // console.log(data, "data");
    try {
      setLoading(true);

      const update = await axios.patch(
        `${AppConstant.baseURL}/testimonials/updatetestimonials`,
        formData
      );
      if (update.data.code === 201) {
        setOpen(false);
        toast.success("Testimonial Updated successfully !");
        setFetchAgain((prev) => !prev);
        clearAll();
      } else {
        setOpen(false);
        toast.error("Something went wrong !");
      }
      setLoading(false);
    } catch (error) {
      setOpen(false);
      toast.error("Something went wrong !");
      console.log(error);
      setLoading(false);
    }

    // console.log(create, "createfaq");
  };

  const handleUpdate = () => {
    updateTestimonial();
    // console.log("submitting form");
    // console.log(questionRef.current.value, "question Ref");
    // console.log(pageurlRef.current.value, "pageurl Ref");
  };

  useEffect(() => {
    if (rowData) {
      setTestimonialData({
        id: rowData?.id,
        user_name: rowData?.user_name,
        image: rowData?.image,
        designation: rowData?.designation,
        comment: rowData?.comment,
        active: active,
      });
    }
  }, [rowData]);

  useEffect(() => {
    setTestimonialData({ ...testimonialData, active: active });
  }, [active]);

  // console.log(rowData, "rowData");
  return (
    <div className="flex  w-[70%] m-auto">
      <div className="bg-[#f4f4f4] w-full  mt-5 p-4 rounded-lg flex flex-col gap-4 text-nowrap">
        <h1 className="text-center font-semibold text-xl tracking-wide">
          Update Testimonial
        </h1>
        <TextField
          id="filled-multiline-flexible"
          label="Username:"
          multiline
          onChange={(e) =>
            setTestimonialData({
              ...testimonialData,
              user_name: e.target.value,
            })
          }
          maxRows={4}
          variant="outlined"
          name="user_name"
          type="text"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${testimonialData?.user_name}`}
        />

        <div className="flex gap-4 items-center">
          <InputFileUpload title={"Update Image"} onChange={picUpload} />
          {pic ? (
            <img src={pic} className="size-12" />
          ) : rowData?.image ? (
            <img
              className="size-10"
              src={`${AppConstant.imageURL}/${rowData?.image}`}
              alt="image"
            />
          ) : (
            <p>Upload Image</p>
          )}
        </div>

        {/* <div className="flex gap-4 border  border-[#999] px-2 py-1 rounded ">
          <label>Image:</label>
          <input
            type="file"
            onChange={picUpload}
            name="image"
            className="w-full outline-none bg-transparent tracking-wide"
          />
          {pic ? (
            <img src={pic} className="size-12" />
          ) : rowData?.image ? (
            <img
              className="size-10"
              src={`cdn1.zonettech.com/imagePath/${rowData?.image
                .split("/")
                .slice(5)
                .join("/")}`}
              alt="image"
            />
          ) : (
            <p>Upload Image</p>
          )}
        </div> */}

        <TextField
          id="filled-multiline-flexible"
          label="Designation:"
          multiline
          onChange={(e) =>
            setTestimonialData({
              ...testimonialData,
              designation: e.target.value,
            })
          }
          maxRows={4}
          variant="outlined"
          name="designation"
          type="text"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${testimonialData?.designation}`}
        />

        <TextField
          id="filled-multiline-flexible"
          label="Comment:"
          multiline
          onChange={(e) =>
            setTestimonialData({
              ...testimonialData,
              comment: e.target.value,
            })
          }
          maxRows={4}
          variant="outlined"
          name="comment"
          type="text"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${testimonialData?.comment}`}
        />

        {/* <div className="flex gap-4 border  border-[#999] px-2 py-1 rounded "> */}
        {/* <input
            ref={activeRef}
            name="active"
            type="number"
            className="w-full outline-none bg-transparent tracking-wide"
            placeholder="Enter Active"
          /> */}
        {/* </div> */}
        <BasicSelect
          title="Active"
          value={active}
          setvalue={setActive}
          list={[
            { id: 0, name: "false" },
            { id: 1, name: "true" },
          ]}
        />
        <Stack direction="row" spacing={2} justifyContent={"center"}>
          <Button
            color="error"
            variant="contained"
            onClick={() => setOpen(false)}
          >
            Discard
          </Button>
          <LoadingButton
            color="success"
            size="medium"
            loading={loading}
            variant="contained"
            onClick={handleUpdate}
          >
            <span>Update</span>
          </LoadingButton>
        </Stack>
      </div>
    </div>
  );
};
export default TestimonialUpdateModule;
