import { useEffect, useRef } from "react";
import { useState } from "react";
// import BasicDatePicker from "./dateComponent";
import Modal from "@mui/material/Modal";
import BasicMenu from "./columnFilter";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Conformation from "./Conformation";
import { formatDate } from "./tableAction";
import ToggleButton from "./toggleButton";
import { Pagination } from "@mui/material";
import { Button } from "@mui/material";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const temp = () => {
  return <></>;
};

const CustomTableComponent = ({
  title = "",
  data = [],
  columns = [],
  search = true,
  dateFilter = true,
  download = true,
  columnShow = columns.length,
  columnFilter = true,
  addNew = true,
  completeHeader = true,
  action = true,
  Module = temp,
  PopUp = temp,
  UpdateModule = temp,
  deleteapi = () => {},
  statusChange = () => {},
}) => {
  const startRef = useRef();
  const endRef = useRef();
  const currPageRef = useRef();

  const [open, setOpen] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [conformationPopUpOpen, setConformationPopUpOpen] = useState(false);
  const [tabledata, setTableData] = useState(data);
  // console.log(tabledata, "table Data");
  const [showRowData, setShowRowData] = useState(10);
  const [totalPage, setTotalPage] = useState(data.length / showRowData);
  // const [currPage, setCurrPage] = useState(1);
  // const [start, setStart] = useState(currPage * showRowData - showRowData);
  // const [end, setEnd] = useState(currPage * showRowData);

  const [searchText, setSearchText] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [columnsData, setColumnsData] = useState(columns.slice(0, columnShow));
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });
  // console.log(tabledata, "table Data");
  const [conformationComponent, setConformationComponent] = useState(
    <Conformation
      setConformationPopUpOpen={setConformationPopUpOpen}
      title="Are You sure ?"
      // onConfirm={handleDelete}
      // props={id}
    />
  );
  const [modalComponent, setModalComponent] = useState(<Module />);

  const wordLimit = 3

  const handleDateFromChange = (e) => {
    const dateObject = new Date(e);
    // console.log(dateObject, "new Date");
    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "long" }); // Get full month name
    const year = dateObject.getFullYear();
    // console.log(day, "day");
    const dateFormated = `${day} ${month} ${year}`;
    setDateFrom(dateObject);

    // setDateFrom(e.target.value);
  };
  const handleDateToChange = (e) => {
    const dateObject = new Date(e);
    // console.log(dateObject, "new Date");
    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "long" }); // Get full month name
    const year = dateObject.getFullYear();
    // console.log(day, "day");
    const dateFormated = `${day} ${month} ${year}`;
    setDateTo(dateObject);

    // setDateTo(e.target.value);
  };

  const filterDataByDate = (data, fromDate, toDate) => {
    if (!fromDate || !toDate) return data;

    return data.filter((item) => {
      const itemDate = new Date(item.Date);

      return itemDate >= fromDate && itemDate <= toDate;
    });
  };

  const searchFilterData = (query, d) => {
    return d.filter((item) => {
      return Object.keys(item).some((key) => {
        return String(item[key]).toLowerCase().includes(query.toLowerCase());
      });
    });
  };

  // const downloadCSV = () => {
  //   const csvRows = [];
  //   const headers = columnsData.map((col) => {
  //     if (col.label == "Actions") return;
  //     return col.label;
  //   });
  //   csvRows.push(headers.join(","));

  //   tabledata.forEach((row) => {
  //     const values = columnsData.map((col) => {
  //       const escaped = row[col.name];
  //       return `"${escaped}"`;
  //     });
  //     csvRows.push(values.join(","));
  //   });

  //   const csvString = csvRows.join("\n");
  //   const blob = new Blob([csvString], { type: "text/csv" });
  //   // console.log(blob, "blob");
  //   const url = window.URL.createObjectURL(blob);
  //   // console.log(url, "url");
  //   const a = document.createElement("a");

  //   a.setAttribute("hidden", "");
  //   a.setAttribute("href", url);
  //   // console.log(a, "a object");
  //   a.setAttribute("download", "table_data.csv");

  //   document.body.appendChild(a);

  //   a.click();
  //   document.body.removeChild(a);
  // };

  const handleSort = (columnName) => {
    let direction = "asc";
    if (sortConfig.key === columnName && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: columnName, direction });

    const sortedData = [...tabledata].sort((a, b) => {
      if (a[columnName] < b[columnName]) return direction === "asc" ? -1 : 1;
      if (a[columnName] > b[columnName]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setTableData(sortedData);
  };
  const handleDelete = (id) => {
    deleteapi(id);
    // console.log(id, "delteding id");
    // const filteredData = tabledata.filter((item) => item.id !== id);
    // setTableData(filteredData);
  };

  useEffect(() => {
    //filterByDate
    if (dateFilter && dateFrom && dateTo) {
      const filteredData = filterDataByDate(data, dateFrom, dateTo);
      setTableData(filteredData);
      // console.log(filteredData, "filtered by date");
    }
  }, [dateFrom, dateTo]);

  useEffect(() => {
    // filterBySearch
    if (searchText.length > 0) {
      // console.log(searchText, "searchText");
      // console.log(filterData(searchText, data), "filterData");

      const filteredData = searchFilterData(searchText, data);
      // console.log(searchFilterData, "filteredData");
      setTableData(filteredData);
      // setTableData(columns);
    } else {
      currPageRef.current = 1;
    }
  }, [searchText]);
  // console.log(module, "module");
  // console.log(tabledata, "tableData");
  // console.log(dateFrom, "dateFrom");
  // console.log(dateTo, "dateTo");
  useEffect(() => {
    // console.log(data, "updaed data");
    setTableData(data);
  }, [data]);

  useEffect(() => {
    if (open === false) {
      setModalComponent(temp);
    }
  }, [open]);

  // const handlePagination = (e) => {
  //   currPageRef.current = parseInt(e.target.innerText);

  //   startRef.current = currPageRef.current * showRowData - showRowData;
  //   endRef.current = currPageRef.current * showRowData;
  //   setTableData(data?.slice(startRef.current, endRef.current));
  // };

  // useEffect(()=>{

  // },[currPage])
  // useEffect(() => {
  //   setShowRowData(10);
  //   setTotalPage(Math.ceil(data.length / showRowData));
  //   currPageRef.current = 1;

  //   startRef.current = currPageRef.current * showRowData - showRowData;
  //   endRef.current = currPageRef.current * showRowData;
  //   setTableData(data?.slice(startRef.current, endRef.current));
  // }, [data.length]);

  // console.log(open, "open in table");
  return (
    <div className="px-5 flex flex-col gap-4 pb-5">
      {completeHeader && (
        <>
          <header className="flex justify-between py-6  items-center">
            <div className="flex flex-row  justify-between items-center w-full flex-wrap ">
              <div className=" flex">
                {search && (
                  // <input
                  //   className="border px-3 py-1 outline-none"
                  //   placeholder="Search..."
                  //   value={searchText}
                  //   onChange={(e) => setSearchText(e.target.value)}
                  //   type="text"
                  // />
                  <TextField
                    variant="outlined" // Choose "outlined", "filled", or "standard"
                    placeholder="Search..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    type="text"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ccc", // Set border color
                        },
                        "&:hover fieldset": {
                          borderColor: "#888", // Border color on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#333", // Border color on focus
                        },
                      },
                      "& .MuiInputBase-input": {
                        padding: "10px 14px", // Adjust padding to match original input
                      },
                    }}
                  />
                )}
              </div>
              <div className="flex flex-col sm:flex-row gap-4">
                {dateFilter && (
                  <>
                    <div className="border px-4 py-2">
                      <label>From:</label>
                      <input
                        className="outline-none"
                        type="date"
                        onChange={(e) => handleDateFromChange(e.target.value)}
                      />
                    </div>
                    <div className="border px-4 py-2">
                      <label>From:</label>
                      <input
                        className="outline-none"
                        type="date"
                        onChange={(e) => handleDateToChange(e.target.value)}
                      />
                    </div>
                    {/* 
                  <BasicDatePicker
                    title="From"
                    selectedDate={dateFrom}
                    handleDateChange={handleDateFromChange}
                  />
                  <BasicDatePicker
                    title="To"
                    selectedDate={dateTo}
                    handleDateChange={handleDateToChange}
                  /> */}
                  </>
                )}
              </div>
            </div>
            {/* <div className="text-3xl ">{title}</div> */}
            <div className="flex gap-8 justify-center items-center">
              {addNew && (
                <Button
                  className="w-36 flex items-center justify-center gap-2 p-1"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setModalComponent(
                      <Module
                        rowData=""
                        setOpen={setOpen}
                        setTableData={setTableData}
                      />
                    );
                    setOpen(true);
                  }}
                >
                  Add New
                  <div>
                    <svg
                      enableBackground="new 0 0 50 50"
                      height="20px"
                      id="Layer_1"
                      version="1.1"
                      viewBox="0 0 50 50"
                      width="20px"
                      xmlSpace="preserve"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <rect fill="none" height={50} width={50} />
                      <line
                        fill="none"
                        stroke="#fff"
                        strokeMiterlimit={10}
                        strokeWidth={4}
                        x1={9}
                        x2={41}
                        y1={25}
                        y2={25}
                      />
                      <line
                        fill="none"
                        stroke="#fff"
                        strokeMiterlimit={10}
                        strokeWidth={4}
                        x1={25}
                        x2={25}
                        y1={9}
                        y2={41}
                      />
                    </svg>
                  </div>
                </Button>
                // <Tooltip title="Add New">
                //   <IconButton
                //     onClick={() => {
                //       setModalComponent(
                //         <Module
                //           rowData=""
                //           setOpen={setOpen}
                //           setTableData={setTableData}
                //         />
                //       );
                //       setOpen(true);
                //     }}
                //   >
                //     <div className="bg-[#EBD37D] flex justify-center items-center gap-2 px-2 py-1 rounded">
                //       <div className="text-[16px] font-medium">Add New</div>

                //       <div>
                //         <svg
                //           enableBackground="new 0 0 50 50"
                //           height="20px"
                //           id="Layer_1"
                //           version="1.1"
                //           viewBox="0 0 50 50"
                //           width="20px"
                //           xmlSpace="preserve"
                //           xmlns="http://www.w3.org/2000/svg"
                //           xmlnsXlink="http://www.w3.org/1999/xlink"
                //         >
                //           <rect fill="none" height={50} width={50} />
                //           <line
                //             fill="none"
                //             stroke="#000000"
                //             strokeMiterlimit={10}
                //             strokeWidth={4}
                //             x1={9}
                //             x2={41}
                //             y1={25}
                //             y2={25}
                //           />
                //           <line
                //             fill="none"
                //             stroke="#000000"
                //             strokeMiterlimit={10}
                //             strokeWidth={4}
                //             x1={25}
                //             x2={25}
                //             y1={9}
                //             y2={41}
                //           />
                //         </svg>
                //       </div>
                //     </div>
                //   </IconButton>
                // </Tooltip>
              )}

              {/* {download && (
                <Tooltip title="Download">
                  <IconButton>
                    <button onClick={downloadCSV}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                      >
                        <path
                          d="M9.87816 18.622C11.0494 19.794 12.9488 19.7946 14.1208 18.6234C14.1213 18.6229 14.1217 18.6225 14.1222 18.622L17.3332 15.411C17.7036 15.0014 17.6717 14.369 17.2621 13.9986C16.8807 13.6537 16.2999 13.6543 15.9192 14L12.9932 16.927L13.0002 1.50003C13.0001 0.947703 12.5524 0.5 12.0001 0.5C11.4479 0.5 11.0002 0.947703 11.0002 1.49998L10.9912 16.908L8.08116 14C7.69041 13.6095 7.05713 13.6098 6.66666 14.0005C6.27619 14.3913 6.27643 15.0245 6.66718 15.415L9.87816 18.622Z"
                          fill="#333333"
                        />
                        <path
                          d="M23 16.5C22.4477 16.5 22 16.9477 22 17.5V21.5C22 22.0523 21.5523 22.5 21 22.5H3C2.44772 22.5 2.00002 22.0523 2.00002 21.5V17.5C2.00002 16.9477 1.55231 16.5 1.00003 16.5C0.447703 16.5 0 16.9477 0 17.5V21.5C0 23.1569 1.34316 24.5 3 24.5H21C22.6568 24.5 24 23.1569 24 21.5V17.5C24 16.9477 23.5523 16.5 23 16.5Z"
                          fill="#333333"
                        />
                      </svg>
                    </button>
                  </IconButton>
                </Tooltip>
              )} */}
              {columnFilter && (
                <Tooltip title="Filter">
                  <BasicMenu
                    columns={columns}
                    setColumnsData={setColumnsData}
                    columnShow={columnShow}
                  />
                </Tooltip>
              )}
            </div>
          </header>
        </>
      )}

      <div //main table
        className="overflow-x-scroll relative"
      >
        <table className="w-full text-nowrap">
          <thead className=" text-left">
            <tr className=" border-b">
              {
                <th className="py-2 pr-12 sticky right-0 bg-white">
                  <div className="flex gap-4">
                    <div> {"RN"}</div>
                  </div>
                </th>
              }
              {columnsData?.map((column, index) => {
                if (column.name === "image") {
                  return (
                    <th key={index} className={`py-2 pr-24 cursor-default `}>
                      <div className="flex gap-4">
                        <div> {column.label}</div>
                      </div>
                    </th>
                  );
                }
                return (
                  <th
                    key={index}
                    className={`py-2 pr-24  cursor-pointer `}
                    onClick={() => {
                      column?.option?.sort !== false && handleSort(column.name);
                    }}
                  >
                    <div className="flex gap-4">
                      <div> {column.label}</div>
                      {column?.option?.sort === false ? (
                        ""
                      ) : (
                        <div
                          className={` duration-100 ${
                            sortConfig.direction === "asc" &&
                            sortConfig.key === column.name
                              ? "rotate-0"
                              : "rotate-180"
                          }`}
                        >
                          <ArrowDropUpIcon />
                        </div>
                      )}
                    </div>
                  </th>
                );
              })}
              {action && (
                <th className="py-2 px-10 sticky right-0 bg-white">
                  <div className="flex gap-4">
                    <div> {"Actions"}</div>
                  </div>
                </th>
              )}
            </tr>
          </thead>

          <tbody className="">
            {tabledata?.map((row, rowIndex) => (
              <tr
                className="border-b text-[#030303] text-[16px] font-normal leading-7 text-left relative"
                key={rowIndex}
              >
                {<td className="py-2 ">{rowIndex + 1}</td>}
                {columnsData.map((column, columnIndex) => {
                  if (column?.option?.tablebodyCustom) {
                    return column?.option?.tablebodyCustom(
                      row,
                      column,
                      setOpen,
                      setModalComponent
                    );
                  }
                  // console.log(column, "coloumn");
                  if (column.name === "active") {
                    // console.log(row.id, "table id");
                    return (
                      <td
                        className={`py-2 pr-10 max-w-[50px] bg-white `}
                        key={columnIndex}
                      >
                        <ToggleButton
                          id={row?.id ? row?.id : row?.p_id}
                          value={row[column.name]}
                          statusChange={statusChange}
                        />
                      </td>
                    );
                  }
                  return (
                    <td
                      className={`py-2 pr-10 max-w-[60px] truncate bg-white 
                        ${
                          typeof row[column.name] === 'number' 
                          ? "" 
                          : row[column.name].split(" ").length > wordLimit
                          ? "hover:whitespace-normal  hover:absolute  hover:max-w-[400px] hover:z-[10] hover:border hover:rounded hover:p-2 hover:break-all" 
                          : ""
                         }`}
                      key={columnIndex}
                    >
                      {row[column.name]}
                    </td>
                  );
                })}
                {action && (
                  <td className="py-2 px-10  sticky right-0 bg-white">
                    <div className="flex gap-5">
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => {
                            setModalComponent(
                              <Conformation
                                setConformationPopUpOpen={setOpen}
                                title="Are You sure ?"
                                onConfirm={handleDelete}
                                props={row["id"] ? row["id"] : row["p_id"]}
                              />
                            );
                            setOpen(true);
                          }}
                        >
                          <div //delete
                          >
                            <img src="/assets/delete.svg" />
                          </div>
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() => {
                            setModalComponent(
                              <UpdateModule rowData={row} setOpen={setOpen} />
                            );
                            // console.log("ediing");
                            setOpen(true);
                          }}
                        >
                          <div //edite
                          >
                            <img src="/assets/edit.svg" />
                          </div>
                        </IconButton>
                      </Tooltip>
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <div className="flex justify-center">
        <Pagination
          count={totalPage}
          // page={currPageRef.current}
          onChange={handlePagination}
          hidePrevButton
          hideNextButton
        />
      </div> */}
      <Modal
        keepMounted
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="overflow-y-scroll"
      >
        {modalComponent}
      </Modal>
    </div>
  );
};
export default CustomTableComponent;
