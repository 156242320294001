import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import Login from "./components/Login";
import Signup from "./components/Signup";

import DashboardLayout from "./components/dashboard/DashboardLayout";
import Profile from "./components/dashboard/profile";
import Faq from "./components/dashboard/faq/faq";
import Blog from "./components/dashboard/blog/Blog";
import Testimonials from "./components/dashboard/testimonials/Testimonial";
import ContactUs from "./components/dashboard/contactUs/contactUs";

// import { createRoot } from "react-dom/client";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Outlet />,
      // errorElement: <ErrorPage />,
      children: [
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "signup",
          element: <Signup />,
        },
        {
          index: true,
          element: <Login />,
        },
        {
          path: "dashboard",
          element: <DashboardLayout />,
          children: [
            {
              index: true,
              path: "profile",
              element: <Profile />,
            },
            {
              path: "faq",
              element: <Faq />,
            },
            {
              path: "blog",
              element: <Blog />,
            },
            {
              path: "contactus",
              element: <ContactUs />,
            },
            {
              path: "testimonials",
              element: <Testimonials />,
            },
            
          ],
        },
      ],
    },
  ]);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
