import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Avatar, IconButton, Paper, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/authcontext";
import { deepOrange } from "@mui/material/colors";
import { toast } from "react-toastify";
import AppConstant from "../../constants/AppConstant"
import { Button } from "@mui/material";

import axios from "axios";
const Profile = () => {
  const { user, setUser, auth } = useContext(AuthContext);
  console.log(user, "user info in profile");

  const [name, setName] = useState(user?.username);
  const [email, setEmail] = useState(user?.email);

  const handleChangeName = async () => {
    try {
      const response = await axios.post(
        `${AppConstant.baseURL}/auth/changename`,
        { user_id: user?.user_id, username: name }
      );

      if (response.data.code === 200) {
        toast.success(`${user.username} updated to ${name}`);
        setUser({ ...user, username: name });
        // setName(user?.username);
      }
    } catch (error) {
      console.log(error, "error in fetching hangle change name");
    }
  };

  useEffect(() => {
    if (user?.username) {
      setName(user?.username);
    }
  }, [user?.username]);

  return (
    <div
      className=" flex m-auto mt-10  w-fit
    "
    >
      <Paper elevation={3}>
        <div className=" p-4 flex flex-col gap-4  rounded  w-full">
          <div className="text-2xl font-semibold text-center">Profile</div>
          <div className="flex justify-center">
            <IconButton
              aria-controls="avatar-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <Avatar sx={{ bgcolor: deepOrange[500] }}>
                {user?.username ? (
                  user?.username[0].toUpperCase()
                ) : (
                  <AccountCircleIcon />
                )}
              </Avatar>
            </IconButton>
          </div>
          <div className="w-full">
            <TextField
              id="filled-multiline-flexible"
              label="Email"
              multiline
              maxRows={4}
              variant="outlined"
              className="w-full"
              value={`${user?.email}`}
              disabled
              InputProps={{
                style: { pointerEvents: 'none', border: 'none' },
                disableUnderline: true,
              }}
              sx={{
                '& .MuiOutlinedInput-root.Mui-disabled': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  backgroundColor: 'transparent', 
                },
                '& .MuiOutlinedInput-root': {
                  '&:focus-within fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                },
              }}
            />

          </div>
          <div className="flex flex-col items-center gap-4 w-full">
            <TextField
              id="filled-multiline-flexible"
              className="w-full"
              label="Name"
              multiline
              maxRows={4}
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
              // defaultValue="Default Value"
              value={name || ''}
            />
            {user?.username !== name && (
              // <button onClick={handleChangeName}>
              //   <DoneIcon color="primary" />
              // </button>
              <Button
              className="w-36 flex items-center justify-center gap-2 p-1"
              variant="contained"
              color="primary"
              onClick={handleChangeName}
            >
              Submit
            </Button>
            )}
          </div>
        </div>
      </Paper>
    </div>
  );
};
export default Profile;
