import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../context/authcontext";
import { setCookie } from "../util/cookies/cookiesFunctions";
import AppConstant from "../constants/AppConstant";

const Signup = () => {
  const { user, auth, setAuth } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [validationMessages, setValidationMessages] = useState({
    email: "",
    password: "",
  });

  const [passfiled, setpassfiled] = useState(false);

  const [number, setnumber] = useState(false);
  const [special, setspecial] = useState(false);
  const [uppercase, setuppercase] = useState(false);
  const [lengths, setlengths] = useState(false);


  const validatePassword = (password) => {
    const validPasswordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*_])[A-Za-z\d!@#$%^&*_]{8,}$/;
    return validPasswordRegex.test(password); // Placeholder, replace with actual validation
  };

  const handleChange = (key) => (event) => {
    setFormData({ ...formData, [event.target.id]: event.target.value });

    if (key === "password") {
      // console.log("password filed selected");
      setpassfiled(true);

      const isvalidatepassword = validatePassword(event.target.value);
      // console.log(isvalidatepassword, "isvalidatepassword");
      if (isvalidatepassword) {
        setpassfiled(false);
      }

      const capitalRegex = /[A-Z]/;
      const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-=|]/;
      const numberRegex = /[0-9]/;
      const lengthRegex = event.target.value.length;
      // console.log(lengthRegex, "lengthRegex");

      capitalRegex.test(event.target.value)
        ? setuppercase(true)
        : setuppercase(false);
      specialCharRegex.test(event.target.value)
        ? setspecial(true)
        : setspecial(false);
      numberRegex.test(event.target.value) ? setnumber(true) : setnumber(false);
      lengthRegex >= 8 ? setlengths(true) : setlengths(false);
      const passwordValidationResults = validatePassword(event.target.value);
      console.log(passwordValidationResults.number);
      setValidationMessages((prev) => ({
        ...prev,
        password: passwordValidationResults
          ? ""
          : "Password Criteria is not matched",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, password, confirmPassword } = formData;

    if (password !== confirmPassword) {
      toast.error("Passwords do not match !", {
        position: "top-right",
      });

      return;
    }

    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    // console.log(password, "passowrd");
    // console.log(passwordRegex.test(password));
    if (!passwordRegex.test(password)) {
      toast.error(
        "Password must be at least 6 characters long, contain one uppercase letter, one number, and one special character.",
        {
          position: "top-right",
        }
      );
      // toast.error(
      //   "Password must be at least 6 characters long, contain one uppercase letter, one number, and one special character."
      // );
      return;
    }

    const userData = {
      name: name,
      email: email,
      password: password,
    };
    // console.log(userData, "userDatauserData");

    // console.log(signupapi(), "signup Status");
    const SignupApi = async () => {
      try {
        const response = await axios.post(
          `${AppConstant.baseURL}/auth/signup`,
          userData
        );
        // console.log(response, "signup response");

        if (response.data.code === 200) {
          // console.log(response.data, "response");

          // user.current = { email: email };
          // setCookie("userToken", response.data.token);
          // setAuth(true);
          toast.success(response.data.message);
          navigate("/login");
        } else {
          setAuth(false);
          toast.error("Failed to sign up. Please try again.");
        }
      } catch (error) {
        toast.error("Failed to sign up. Please try again.");
        setAuth(false);
      }
    };
    SignupApi();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // useEffect(() => {
  //   if (auth) {
  //     toast.success("Login Successfully");
  //     navigate("/dashboard");
  //   }
  //   console.log(auth, user, "auth,user");
  // }, [auth]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#6953C2] p-[10px]">
      <div className="bg-[#111]/60 p-7 sm:p-12 rounded-lg shadow-lg w-full max-w-lg">
        <div className="flex justify-center mb-7">
          <img src="/assets/zonet1.png" alt="Logo" className="h-8 sm:h-12" />
        </div>
        <h2 className="text-2xl text-center mb-7 text-white font-semibold">
          Sign Up
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-7">
            <label className="block text-white text-lg mb-2" htmlFor="name">
              Name *
            </label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={handleChange("name")}
              placeholder="Enter your name"
              className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-7">
            <label className="block text-white text-lg mb-2" htmlFor="email">
              Email *
            </label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange("email")}
              placeholder="Enter your email"
              className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-7 relative">
            <label className="block text-white text-lg mb-2" htmlFor="password">
              Password *
            </label>
            <div className="flex items-center pr-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 bg-white">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={formData.password}
                onChange={handleChange("password")}
                placeholder="Enter your password"
                className="w-full px-3 py-2 outline-none bg-transparent"
              />
              <div
                className="cursor-pointer ml-2"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <VisibilityOff className="text-gray-600" />
                ) : (
                  <Visibility className="text-gray-600" />
                )}
              </div>
            </div>

            {passfiled ? (
              <div className="validpasswword flex items-center justify-between">
                <div className="flex flex-col">
                  {/* {/ {special ? ( /} */}
                  <div style={{ color: special ? "green" : "red" }}>
                    One special character
                  </div>

                  {/* {/ {uppercase ? ( /} */}
                  <div style={{ color: uppercase ? "green" : "red" }}>
                    One uppercase character
                  </div>
                </div>
                <div className="flex flex-col">
                  {/* {/ {lengths ? ( /} */}
                  <div style={{ color: lengths ? "green" : "red" }}>
                    8 characters minimum
                  </div>

                  {/* {/ {number ? ( /} */}
                  <div
                    style={{ color: number ? "green" : "red" }}
                    className="uppercasee"
                  >
                    One number
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

          </div>
          <div className="mb-7 relative">
            <label
              className="block text-white text-lg mb-2"
              htmlFor="confirmPassword"
            >
              Confirm Password *
            </label>
            <div className="flex items-center pr-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 bg-white">
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange("confirmPassword")}
                placeholder="Confirm your password"
                className="w-full px-3 py-2  rounded-md shadow-sm focus:outline-none"
              />
              <div
                className="cursor-pointer ml-2"
                onClick={toggleConfirmPasswordVisibility}
              >
                {showConfirmPassword ? (
                  <VisibilityOff className="text-gray-600" />
                ) : (
                  <Visibility className="text-gray-600" />
                )}
              </div>
            </div>
            <p className="text-gray-400 text-s mt-2">
              Must be same as password
            </p>
          </div>

          <button
            type="submit"
            className="w-full text-white py-2 rounded-md bg-[#6953C2]"
          >
            SIGN UP
          </button>
        </form>
        <div className="mt-6 text-center">
          <p className="text-gray-400 text-lg">
            Already have an account?{" "}
            <Link to="/login" className="text-white font-semibold">
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
