import { useContext, useEffect, useState } from "react";
import CustomTableComponent from "../customTableComponent/CustomTableComponent";
import axios from "axios";
import { AuthContext } from "../../../context/authcontext";
import ContactInfoUpdateModule from "./updateModule";
import { toast } from "react-toastify";
import AppConstant from "../../../constants/AppConstant"
const columns = [
  { id: 1, name: "name", label: "Name" },
  { id: 2, name: "email", label: "Email" },
  { id: 3, name: "mobile", label: "Mobile Number" },
  { id: 4, name: "subject", label: "Subject" },
  { id: 5, name: "message", label: "Message" },
  { id: 6, name: "revert_back", label: "Revert Back" },
  { id: 7, name: "active", label: "Active" },
];
const data = [
  // {
  //   id: 1,
  //   name: "deependu",
  //   email: "deependuv@gmail.com",
  //   mobile: "941xxxxxxx",
  //   subject: "problem hi problem h",
  //   message: "Hello there!!",
  //   revert_back: "1",
  //   active: 1,
  // },
];

const ContactUs = () => {
  const [info, setInfo] = useState([]);
  const {
    user,
    auth,
    setAuth,
    fetchAgain,
    setFetchAgain,
    loading,
    setLoading,
  } = useContext(AuthContext);

  const deleteapi = async (id) => {
    // console.log(id, "deleteid");
    const deleteApiCall = await axios.post(
      `${AppConstant.baseURL}/contact/deletecontact`,
      { id: id }
    );
    if (deleteApiCall.data.code === 200) {
      toast.success("deleted successfully");
      setFetchAgain((prev) => !prev);
    } else {
      toast.error("something went wrong");
    }
    // console.log(deleteApiCall, "delete api call");
  };

  const statusChange = async (id, active) => {
    // console.log(id, active, "deleteid");
    try {
      const StatusApiCall = await axios.post(
        `${AppConstant.baseURL}/contact/status`,
        { id: id, active: active }
      );
      if (StatusApiCall.data.code === 200) {
        toast.success(StatusApiCall.data.message);
        setFetchAgain((prev) => !prev);
      } else {
        toast.error("something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const getInfo = async () => {
      try {
        setLoading(true);
        const getallInfo = await axios.get(
          `${AppConstant.baseURL}/contact/getcontactinfo`,
          {
            signal: controller.signal,
          }
        );
        if (getallInfo.data.code === 200) {
          setInfo(getallInfo.data.data);
        }
        setLoading(false);
        // console.log(getallInfo.data.data, "get Info");
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getInfo();
    return () => {
      controller.abort();
    };
  }, [fetchAgain]);

  // useEffect(() => {
  //   console.log(faq, "faq state updated"); // Debug the state updates
  // }, [faq]);
  // console.log(faq, "faq setted");
  return (
    <div className="border">
      <CustomTableComponent
        title=""
        data={info}
        columns={columns}
        search={true}
        dateFilter={false}
        download={true}
        // columnShow={5}
        UpdateModule={ContactInfoUpdateModule}
        // Module={FaqAddModule}
        deleteapi={deleteapi}
        addNew={false}
        statusChange={statusChange}
      />
    </div>
  );
};
export default ContactUs;

//  option: {
//       tablebodyCustom: (row, column) => {
//         if (column.name === "weight") {
//           return <td className={`py-2 pr-24`}>{`${row[column.name]} g`}</td>;
//         }
//       },
//     },
