import axios from "axios";
import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../context/authcontext";
import { getCookie } from "../../util/cookies/cookiesFunctions";
import { toast, useToast } from "react-toastify";
import { Outlet, useNavigate } from "react-router-dom";
import NavScrollExample from "./drawer";
import AppConstant from "../../constants/AppConstant"

const DashboardLayout = () => {
  const { user, setUser, auth, setAuth } = useContext(AuthContext);

  // const userToken = getCookie("userToken");
  axios.defaults.withCredentials = true;
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();

    const getUserInfo = async (email) => {
      try {
        const userData = await axios.get(
          `${AppConstant.baseURL}/auth/getuserinfo`,
          { signal: controller.signal }
        );
        if (userData.data.code === 500) {
          setAuth(0);
          toast.error("Authentication Failed !");
        }
        if (userData.data.code === 402) {
          toast.error("Email does not exist");
          setAuth(0);

          return;
        }
        if (userData.data.code === 200) {
          setAuth(1);
          setUser(userData.data.data);
          return;
        } else {
          setUser(0);
          toast.error("Something went wrong");
        }
      } catch (error) {
        setAuth(0);
        console.log(error, "error in geting user");
      }
    };

    getUserInfo();

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div>
      <NavScrollExample Outlet={<Outlet />} />
    </div>
  );
};

export default DashboardLayout;
