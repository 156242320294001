import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/authcontext";
import MultipleSelectChip from "../../commonComponet/multipleSelect";
import BasicSelect from "../../commonComponet/singleSelete";
import { Button, Stack, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import InputFileUpload from "../../commonComponet/fileUpload/fileUpload";
import AppConstant from "../../../constants/AppConstant"

const BlogPostAddModule = ({ setOpen }) => {
  const [personName, setPersonName] = useState([]);
  const [active, setActive] = useState(0);
  const [pic, setPic] = useState(null);
  const {
    user,
    auth,
    setAuth,
    fetchAgain,
    setFetchAgain,
    loading,
    setLoading,
  } = useContext(AuthContext);

  const [blog, setBlog] = useState({
    title: "",
    featured_image: "",
    short_description: "",
    description: "",
    quotes: "",
    author_name: "",
    social_links: "",
    related_projects: "",
    related_language: "",
    cat_arr: "",
    priority: "",
    active: active,
  });

  const clearAll = () => {
    setBlog({
      title: "",
      featured_image: "",
      short_description: "",
      description: "",
      quotes: "",
      author_name: "",
      social_links: "",
      related_projects: "",
      related_language: "",
      cat_arr: "",
      priority: "",
      active: false,
    });
  };

  const createBlogPost = async () => {
    const formData = new FormData();
    formData.append("title", blog?.title);
    formData.append("featured_image", blog?.featured_image);
    formData.append("short_description", blog?.short_description);
    formData.append("description", blog?.description);
    formData.append("quotes", blog?.quotes);
    formData.append("author_name", blog?.author_name);
    formData.append("social_links", blog?.social_links);
    formData.append("related_projects", blog?.related_projects);
    formData.append("related_language", blog?.related_language);
    formData.append("cat_arr", JSON.stringify(personName));
    formData.append("priority", blog?.priority);
    formData.append("active", blog?.active);

    // formData.forEach((value, key) => {
    //   console.log(`${key}:`, value, "key value");
    // });

    try {
      const create = await axios.post(
        `${AppConstant.baseURL}/blog/createBlogDetails`,
        formData
      );
      if (create.data.code === 201) {
        setOpen(false);
        toast.success("Blog Added successfully !");
        setFetchAgain((prev) => !prev);
        clearAll();
      } else {
        setOpen(false);
        toast.error("Something went wrong !");
      }
    } catch (error) {
      console.log(error);
    }

    // console.log(create, "createfaq");
  };

  const handleSubmit = () => {
    createBlogPost();
    clearAll();
    // console.log("submitting form");
    // console.log(questionRef.current.value, "question Ref");
    // console.log(pageurlRef.current.value, "pageurl Ref");
  };

  const picUpload = (e) => {
    const [file] = e.target.files;
    setPic(URL.createObjectURL(file));
    setBlog({
      ...blog,
      featured_image: file,
    });
    // setFile(file);
  };

  useEffect(() => {
    setBlog({ ...blog, active: active });
  }, [active]);

  return (
    <div className="flex  w-[70%] mx-auto relative ">
      <div className="bg-[#f4f4f4]  mt-10 p-4 rounded-lg flex flex-col gap-4  w-full text-nowrap ">
        <h1 className="text-center font-semibold text-xl tracking-wide">
          Add BlogPost
        </h1>

        {/* <div className="flex gap-4 border border-[#999] px-2 py-1 rounded">
          <label>Title:</label>
          <input
            // ref={titleRef}
            type="text"
            onChange={(e) =>
              setBlog({
                ...blog,
                [e.target.name]: e.target.value,
              })
            }
            name="title"
            value={blog?.title}
            className="w-full outline-none bg-transparent tracking-wide "
            placeholder="Enter Title"
          />
        </div> */}

        <TextField
          id="filled-multiline-flexible"
          label="Title"
          multiline
          onChange={(e) =>
            setBlog({ ...blog, [e.target.name]: e.target.value })
          }
          maxRows={4}
          variant="outlined"
          name="title"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${blog?.title}`}
        />

        {/* <div className="flex gap-4 border  border-[#999] px-2 py-1 rounded ">
          <label>featured_image:</label>
          <input
            onChange={picUpload}
            type="file"
            name="featured_image"
            className="w-full outline-none bg-transparent tracking-wide"
          />
          {pic ? <img src={pic} className="size-12" /> : <p>Upload Image</p>}
        </div> */}
        <div className="flex gap-4 items-center">
          <InputFileUpload title={"Featured Image"} onChange={picUpload} />
          {pic ? (
            <img src={pic} className="size-12 hover:scale-125 duration-100" />
          ) : (
            <p>Upload Image</p>
          )}
        </div>

        {/* <div className="flex gap-4 border  border-[#999] px-2 py-1 rounded ">
          <label>short_description:</label>
          <input
            // ref={short_descriptionRef}
            type="text"
            onChange={(e) =>
              setBlog({
                ...blog,
                [e.target.name]: e.target.value,
              })
            }
            value={blog?.short_description}
            name="short_description"
            className="w-full outline-none bg-transparent tracking-wide"
            placeholder="Enter short Description"
          />
        </div> */}

        <TextField
          id="filled-multiline-flexible"
          label="Short Description"
          multiline
          onChange={(e) =>
            setBlog({ ...blog, [e.target.name]: e.target.value })
          }
          maxRows={4}
          variant="outlined"
          name="short_description"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${blog?.short_description}`}
        />

        {/* <div className="flex gap-4 border  border-[#999] px-2 py-1 rounded ">
          <label>description:</label>
          <input
            // ref={descriptionRef}
            onChange={(e) =>
              setBlog({
                ...blog,
                [e.target.name]: e.target.value,
              })
            }
            value={blog?.description}
            type="text"
            name="description"
            className="w-full outline-none bg-transparent tracking-wide"
            placeholder="Enter Description"
          />
        </div> */}
        <TextField
          id="filled-multiline-flexible"
          label="Description"
          multiline
          onChange={(e) =>
            setBlog({ ...blog, [e.target.name]: e.target.value })
          }
          maxRows={4}
          variant="outlined"
          name="description"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${blog?.description}`}
        />

        {/* <div className="flex gap-4 border  border-[#999] px-2 py-1 rounded ">
          <label>quotes:</label>
          <input
            // ref={quotesRef}
            type="text"
            onChange={(e) =>
              setBlog({
                ...blog,
                [e.target.name]: e.target.value,
              })
            }
            value={blog?.quotes}
            name="quotes"
            className="w-full outline-none bg-transparent tracking-wide"
            placeholder="Enter quotes"
          />
        </div> */}

        <TextField
          id="filled-multiline-flexible"
          label="Quotes"
          multiline
          onChange={(e) =>
            setBlog({ ...blog, [e.target.name]: e.target.value })
          }
          maxRows={4}
          variant="outlined"
          name="quotes"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${blog?.quotes}`}
        />

        <div className="flex gap-4">
          {/* <div className="flex gap-4 border  border-[#999] px-2 py-1 rounded ">
            <label>author_name:</label>
            <input
              // ref={author_nameRef}
              type="text"
              onChange={(e) =>
                setBlog({
                  ...blog,
                  [e.target.name]: e.target.value,
                })
              }
              value={blog?.author_name}
              name="author_name"
              className="w-full outline-none bg-transparent tracking-wide"
              placeholder="Enter author_name"
            />
          </div> */}
          <TextField
            id="filled-multiline-flexible"
            label="Author name"
            multiline
            onChange={(e) =>
              setBlog({ ...blog, [e.target.name]: e.target.value })
            }
            maxRows={4}
            variant="outlined"
            name="author_name"
            // defaultValue="Default Value"
            // className="w-full"
            value={`${blog?.author_name}`}
          />

          {/* <div className="flex gap-4 border  border-[#999] px-2 py-1 rounded ">
            <label>social_links:</label>
            <input
              // ref={social_linksRef}
              type="text"
              onChange={(e) =>
                setBlog({
                  ...blog,
                  [e.target.name]: e.target.value,
                })
              }
              value={blog?.social_links}
              name="social_links"
              className="w-full outline-none bg-transparent tracking-wide"
              placeholder="Enter social_links"
            />
          </div> */}
          <TextField
            id="filled-multiline-flexible"
            label="Social Links"
            multiline
            onChange={(e) =>
              setBlog({ ...blog, [e.target.name]: e.target.value })
            }
            maxRows={4}
            variant="outlined"
            name="social_links"
            // defaultValue="Default Value"
            // className="w-full"
            value={`${blog?.social_links}`}
          />
        </div>

        {/* <div className="flex gap-4 border  border-[#999] px-2 py-1 rounded "> */}
        {/* <label>related_projects:</label>
          <input
            // ref={related_projectsRef}
            type="text"
            onChange={(e) =>
              setBlog({
                ...blog,
                [e.target.name]: e.target.value,
              })
            }
            value={blog?.related_projects}
            name="related_projects"
            className="w-full outline-none bg-transparent tracking-wide"
            placeholder="Enter related_projects"
          />
        </div> */}

        <TextField
          id="filled-multiline-flexible"
          label="Related Projects"
          multiline
          onChange={(e) =>
            setBlog({ ...blog, [e.target.name]: e.target.value })
          }
          maxRows={4}
          variant="outlined"
          name="related_projects"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${blog?.related_projects}`}
        />

        {/* <div className="flex gap-4 border  border-[#999] px-2 py-1 rounded ">
          <label>related_language:</label>
          <input
            // ref={related_languageRef}
            type="text"
            onChange={(e) =>
              setBlog({
                ...blog,
                [e.target.name]: e.target.value,
              })
            }
            value={blog?.related_language}
            name="related_language"
            className="w-full outline-none bg-transparent tracking-wide"
            placeholder="Enter related_language"
          />
        </div> */}
        <TextField
          id="filled-multiline-flexible"
          label="Related Language"
          multiline
          onChange={(e) =>
            setBlog({ ...blog, [e.target.name]: e.target.value })
          }
          maxRows={4}
          variant="outlined"
          name="related_language"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${blog?.related_language}`}
        />

        {/* <div className="flex gap-4 border  border-[#999] px-2 py-1 rounded ">
          <label>cat_arr:</label>
          <select
            name="cat_arr"
            multiple
            ref={cat_arrRef}
            className="w-full outline-none bg-transparent tracking-wide"
          >
            <option>aboutus</option>
            <option>contactus</option>
          </select>
        </div> */}
        <div className="w-fit">
          <MultipleSelectChip
            label={"cat arr"}
            names={["IT", "AI", "DM"]}
            personName={personName}
            setPersonName={setPersonName}
          />
        </div>

        <div className="flex gap-4">
          {/* <div className="flex gap-4 border  border-[#999] px-2 py-1 rounded "> */}
          {/* <label>priority:</label>
            <input
              // ref={priorityRef}
              name="priority"
              onChange={(e) =>
                setBlog({
                  ...blog,
                  [e.target.name]: e.target.value,
                })
              }
              value={blog?.priority}
              type="number"
              className="w-full outline-none bg-transparent tracking-wide"
              placeholder="Enter priority"
            /> */}
          {/* </div>  */}
          <TextField
            id="filled-multiline-flexible"
            label="Priority"
            type="text"
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, "");
              setBlog({ ...blog, [e.target.name]: value });
            }}
            inputProps={{
              pattern: "[0-9]*", // Allows only numeric input
              inputMode: "numeric", // Suggests a numeric keyboard on mobile devices
            }}
            maxRows={4}
            variant="outlined"
            name="priority"
            // defaultValue="Default Value"
            // className="w-full"
            value={`${blog?.priority}`}
          />

          {/* <div className="flex gap-4 border  border-[#999] px-2 py-1 rounded "> */}
          <BasicSelect
            title="Active"
            value={active}
            setvalue={setActive}
            list={[
              { id: 0, name: "false" },
              { id: 1, name: "true" },
            ]}
          />
        </div>
        {/* </div> */}

        {/* <div className="flex gap-4 m-auto"> */}
        {/* <div
            className="text-white bg-red-700 px-2 py-1 rounded cursor-pointer"
            onClick={() => setOpen(false)}
          >
            Discard
          </div> */}
        <Stack direction="row" spacing={2} justifyContent={"center"}>
          <Button
            color="error"
            variant="contained"
            onClick={() => setOpen(false)}
          >
            Discard
          </Button>
          {/* <div
            className="text-white bg-green-600 px-2 py-1 rounded cursor-pointer"
            onClick={handleSubmit}
          >
            Submit
          </div> */}
          <LoadingButton
            color="success"
            size="medium"
            loading={loading}
            variant="contained"
            onClick={handleSubmit}
          >
            <span>Submit</span>
          </LoadingButton>
        </Stack>
        {/* </div> */}
      </div>
    </div>
  );
};
export default BlogPostAddModule;
