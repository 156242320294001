import React from "react";

import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "32px",
  borderRadius: "16px",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  //   height: "40vh",
  bgcolor: "#FFF",

  boxShadow: 24,
  p: 4,

  scrollbarWidth: "none", // For Firefox
  msOverflowStyle: "none", // For Internet Explorer and Edge
  "&::-webkit-scrollbar": {
    display: "none", // For Chrome, Safari, and Opera
  },
};
const Conformation = ({
  setConformationPopUpOpen,
  onConfirm = () => {},
  title = "",
  props = "",
}) => {
  return (
    <Box sx={style}>
      <div className="flex flex-col items-center gap-4  justify-center p-5">
        <h1>{title}</h1>
        <div className="flex gap-10">
          <button
            className="bg-[#588b58]/50 px-4 py-2 font-semibold"
            onClick={() => {
              onConfirm(props);
              setConformationPopUpOpen(false);
            }}
          >
            Yes
          </button>
          <button
            className="bg-[#ae3628]/50 px-4 py-2 font-semibold"
            onClick={() => setConformationPopUpOpen(false)}
          >
            No
          </button>
        </div>
      </div>
    </Box>
  );
};

export default Conformation;
