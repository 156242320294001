import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/authcontext";
import BasicSelect from "../../commonComponet/singleSelete";
import { TextField, Button, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import AppConstant from "../../../constants/AppConstant"

const ContactInfoUpdateModule = ({ setOpen, rowData }) => {
  // console.log(rowData, "rowData comming");
  const { fetchAgain, setFetchAgain, loading } = useContext(AuthContext);
  const [active, setActive] = useState(rowData?.active);
  const [revertBack, setrevertBack] = useState(rowData?.revert_back);
  // const nameRef = useRef(null);
  // const emailRef = useRef(null);
  // const mobileRef = useRef(null);
  // const subjectRef = useRef(null);
  // const messageRef = useRef(null);
  // const revertBackRef = useRef(null);
  // const activeRef = useRef(null);
  const [contactInfoData, setcontactInfoData] = useState({
    name: rowData?.name,
    email: rowData?.email,
    mobile: rowData?.mobile,
    subject: rowData?.subject,
    message: rowData?.message,
    revert_back: revertBack,
    id: rowData?.id,
    active: active,
  });
  // console.log(rowData, "rowData");
  const clearAll = () => {
    setcontactInfoData({});
  };

  const updateContactInfo = async () => {
    try {
      const update = await axios.patch(
        `${AppConstant.baseURL}/contact/updatecontact`,
        contactInfoData
      );
      if (update.data.code === 200) {
        setOpen(false);
        toast.success("Contact info updated successfully!");
        setFetchAgain((prev) => !prev);
        clearAll();
      } else {
        setOpen(false);
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while updating contact info.");
    }
  };

  const handleUpdate = () => {
    updateContactInfo();
  };
 useEffect(() => {
  if (rowData) {
    setcontactInfoData({
      name: rowData?.name,
      email: rowData?.email,
      mobile:rowData?.mobile ,
      subject: rowData?.subject,
      message: rowData?.message,
      revert_back: revertBack,
      id: rowData?.id,
      active: active,
    });
  }
}, [rowData]);
useEffect(() => {
  setcontactInfoData({ ...contactInfoData, revert_back: revertBack });
}, [revertBack]);

  useEffect(() => {
    setcontactInfoData({ ...contactInfoData, active: active });
  }, [active]);

  return (
    <div className="flex m-auto w-[70%]">
      <div className="bg-[#f4f4f4]  mt-5 p-4 rounded-lg flex flex-col gap-4 text-nowrap w-full">
        <h1 className="text-center font-semibold text-xl tracking-wide">
          Update Contact Info
        </h1>
        {/* 
        <div className="flex gap-4 border border-[#999] px-2 py-1 rounded">
          <label>Name:</label>
          <input
           onChange={(e) =>
            setcontactInfoData({ ...contactInfoData, name: e.target.value })
          }
            value={contactInfoData?.name}
            type="text"
            name="name"
            className="w-full outline-none bg-transparent tracking-wide"
            placeholder="Enter name"
          />
        </div> */}
        <TextField
          id="filled-multiline-flexible"
          label="Name"
          multiline
          onChange={(e) =>
            setcontactInfoData({
              ...contactInfoData,
              [e.target.name]: e.target.value,
            })
          }
          maxRows={4}
          variant="outlined"
          name="name"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${contactInfoData?.name}`}
        />

        {/* <div className="flex gap-4 border border-[#999] px-2 py-1 rounded">
          <label>Email:</label>
          <input
          onChange={(e) =>
            setcontactInfoData({ ...contactInfoData, email: e.target.value })
          }
            value={contactInfoData?.email}
            type="email"
            name="email"
            className="w-full outline-none bg-transparent tracking-wide"
            placeholder="Enter email"
          />
        </div> */}
        <TextField
          id="filled-multiline-flexible"
          label="Email"
          multiline
          onChange={(e) =>
            setcontactInfoData({
              ...contactInfoData,
              [e.target.name]: e.target.value,
            })
          }
          maxRows={4}
          variant="outlined"
          name="email"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${contactInfoData?.email}`}
        />

        {/* <div className="flex gap-4 border border-[#999] px-2 py-1 rounded">
          <label>Mobile:</label>
          <input
          onChange={(e) =>
            setcontactInfoData({ ...contactInfoData, mobile: e.target.value })
          }
            value={contactInfoData?.mobile}
            type="number"
            name="mobile"
            className="w-full outline-none bg-transparent tracking-wide"
            placeholder="Enter mobile"
          />
        </div> */}
        <TextField
          id="filled-multiline-flexible"
          label="Mobile"
          type="text"
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/g, "");
            if (value.length <= 10) {
              setcontactInfoData({
                ...contactInfoData,
                [e.target.name]: value,
              });
            }
          }}
          inputProps={{
            maxLength: 10, // Optional: Enforce the maximum length in the input field
            pattern: "[0-9]*", // Restrict to numeric values
            inputMode: "numeric", // Suggest numeric keyboard on mobile devices
          }}
          maxRows={4}
          variant="outlined"
          name="mobile"
          value={`${contactInfoData?.mobile}`}
        />

        {/* <div className="flex gap-4 border border-[#999] px-2 py-1 rounded">
          <label>Subject:</label>
          <input
          onChange={(e) =>
            setcontactInfoData({ ...contactInfoData, subject: e.target.value })
          }
            value={contactInfoData?.subject}
            type="text"
            name="subject"
            className="w-full outline-none bg-transparent tracking-wide"
            placeholder="Enter subject"
          />
        </div> */}
        <TextField
          id="filled-multiline-flexible"
          label="Subject"
          multiline
          onChange={(e) =>
            setcontactInfoData({
              ...contactInfoData,
              [e.target.name]: e.target.value,
            })
          }
          maxRows={4}
          variant="outlined"
          name="subject"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${contactInfoData?.subject}`}
        />

        {/* <div className="flex gap-4 border border-[#999] px-2 py-1 rounded">
          <label>Message:</label>
          <textarea
          onChange={(e) =>
            setcontactInfoData({ ...contactInfoData, message: e.target.value })
          }
            value={contactInfoData?.message}
            name="message"
            className="w-full outline-none bg-transparent tracking-wide"
            placeholder="Enter message"
          />
        </div> */}
        <TextField
          id="filled-multiline-flexible"
          label="Message"
          multiline
          onChange={(e) =>
            setcontactInfoData({
              ...contactInfoData,
              [e.target.name]: e.target.value,
            })
          }
          maxRows={4}
          variant="outlined"
          name="message"
          // defaultValue="Default Value"
          // className="w-full"
          value={`${contactInfoData?.message}`}
        />

        {/* <div className="flex gap-4 border border-[#999] px-2 py-1 rounded">
          <label>Revert Back:</label>
          <input
          onChange={(e) =>
            setcontactInfoData({ ...contactInfoData, revert_back: e.target.value })
          }
            value={contactInfoData?.revert_back}
            name="revert_back"
            type="number"
            className="w-full outline-none bg-transparent tracking-wide"
            placeholder="Enter revert back (0 or 1)"
          />
        </div> */}

        <div className="flex gap-4 px-2 py-1 rounded ">
          <BasicSelect
            title="Revert Back"
            value={revertBack}
            setvalue={setrevertBack}
            list={[
              { id: 0, name: "No" },
              { id: 1, name: "Yes" },
            ]}
          />
        </div>


        <div className="flex gap-4 px-2 py-1 rounded ">
          <BasicSelect
            title="Active"
            value={active}
            setvalue={setActive}
            list={[
              { id: 0, name: "false" },
              { id: 1, name: "true" },
            ]}
          />
        </div>

        {/* <div className="flex gap-4 m-auto">
          <div
            className="text-white bg-red-700 px-2 py-1 rounded cursor-pointer"
            onClick={() => setOpen(false)}
          >
            Discard
          </div>
          <div
            className="text-white bg-green-600 px-2 py-1 rounded cursor-pointer"
            onClick={handleUpdate}
          >
            Update
          </div>
        </div> */}
        <Stack direction="row" spacing={2} justifyContent={"center"}>
          <Button
            color="error"
            variant="contained"
            onClick={() => setOpen(false)}
          >
            Discard
          </Button>
          <LoadingButton
            color="success"
            size="medium"
            loading={loading}
            variant="contained"
            onClick={handleUpdate}
          >
            <span>Update</span>
          </LoadingButton>
        </Stack>
      </div>
    </div>
  );
};

export default ContactInfoUpdateModule;
