import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

// const handleCHnage = (e) => {
//   console.log(e.target.files[0], "file e");
// };

export default function InputFileUpload({
  title = "Upload File",
  onChange = () => {},
  multiple = false,
}) {
  // console.log(multiple, "multiple");
  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
      onChange={onChange}
    >
      {title}
      <VisuallyHiddenInput type="file" multiple={multiple} />
    </Button>
  );
}
