import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/authcontext";
import { Link, useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { getCookie, setCookie } from "../util/cookies/cookiesFunctions";
import axios from "axios";
import { toast } from "react-toastify";
import AppConstant from "../constants/AppConstant"

const Login = () => {
  const { user, setUser, auth, setAuth } = useContext(AuthContext);
  axios.defaults.withCredentials = true;
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
  const [formData, setFormData] = useState({ email: "", password: "" });
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formData;

    if (!email || !password) {
      setAuth(0);
      toast.error("All Field Required");
      return;
    }

    const LoginApi = async () => {
      try {
        const response = await axios.post(
          `${AppConstant.baseURL}/auth/login`,
          {
            email,
            password,
          }
        );
        // console.log(response, "authresponse");
        if (response.data.code === 404) {
          setAuth(0);
          toast.error("Password is not set for your account");
          return;
        }
        if (response.data.code === 402) {
          setAuth(0);
          toast.error("Email and password does not match! Try Again");

          return;
          // return;
        }
        if (response.data.code === 400) {
          // console.log("false kar diya");
          setAuth(0);
          // console.log(auth, user, "auth,user");

          toast.error("Invalid Credentials");
          return;
          // return;
        }
        if (response.data.code === 406) {
          setAuth(0);
          toast.error("Invalid Credentials");
        }

        if (response.data.code === 200) {
          setAuth(1);
          // user.current = { email: email };
          // setCookie("userToken", response.data.token);
          // console.log("true kar diya");
          // setUserToken(response.data.data);
          navigate("/dashboard/profile");
          return;
          // console.log(auth, user, "auth,user");
        }
      } catch (err) {
        setAuth(0);
        toast.error("Failed to login. Please try again.");
        // setError(err.response ? err.response.data.message : "Login failed");
        // return false;
      }
    };
    LoginApi();
  };

  // useEffect(() => {
  //   // console.log(userToken, "this is the token");
  //   if (userToken === "undefined" || userToken === undefined) {
  //     console.log("token not found !");
  //     return;
  //   } else if (userToken && auth == 0) {
  //     console.log(userToken, "token data");
  //     tokenVerification();
  //   }
  // }, [userToken]);

  // useEffect(() => {
  //   // console.log("login Use Effect running");
  //   if (auth === 1) {
  //     navigate("/dashboard/profile");
  //     toast.success("login SuccessFul");
  //   }

  //   console.log(auth, user, "auth,user");
  // }, [auth]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#6953C2] p-[10px]">
      <div className="bg-[#111]/60 p-7 sm:p-10 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-center mb-6">
          <img src="/assets/zonet1.png" alt="Logo" className="h-8 sm:h-12" />
        </div>
        <h2 className="text-2xl font-semibold text-center mb-7 text-white">
          Login
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-7">
            <label className="block text-white text-lg mb-2" htmlFor="email">
              Email *
            </label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div className="mb-9 relative">
            <label className="block text-white text-lg mb-2" htmlFor="password">
              Password *
            </label>
            <div className="flex items-center pr-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 bg-white">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Enter your password"
                className="w-full px-3 py-2 outline-none bg-transparent"
              />
              <div
                className="cursor-pointer ml-2"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <VisibilityOff className="text-gray-600" />
                ) : (
                  <Visibility className="text-gray-600" />
                )}
              </div>
            </div>
          </div>
          {/* {<p className="text-red-500 mb-4">{error}</p>} */}
          {/* <div className="flex justify-between items-center mb-7">
            <Link to="/forgot-password" className="text-lg text-white font-semibold">
              Forgot Password?
            </Link>
          </div> */}
          <button
            type="submit"
            className="w-full text-white py-2 rounded-md bg-[#6953C2]"
          >
            LOGIN
          </button>
        </form>
        <div className="mt-6 text-center">
          <p className="text-gray-400 text-lg">
            Don't have an account?{" "}
            <Link to="/signup" className="text-white font-semibold">
              Sign Up
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
